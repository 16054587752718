<template>

    <div class="h-auto rounded-lg flex flex-col overflow-hidden relative">
        <pipeline-status :isMobile="true" class="mt-4"/>
    </div>

</template>

<script>
    const PipelineStatus = () => import('@/components/Globals/Pipeline/PipelineStatus.vue');
    export default {
        props: ['mobile'],
        components: {
            PipelineStatus,
        }
    }
</script>